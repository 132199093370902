/** @jsx jsx */
import { useContext } from 'react'
import { jsx, useThemeUI, Button } from 'theme-ui'
import debounce from 'lodash/debounce'
import { StaticQuery, graphql, Link } from 'gatsby'
import { useLocation } from '@reach/router'

import { PostsViewsContext } from 'src/context/postsViewsContext'
import { PostsToShowContext } from 'src/context/postsToShowContext'
import Card from 'src/components/card'
import Tag from 'src/components/tag'
import EyeIcon from 'src/assets/svgs/eye'

const PostsList = ({
  isHomepage,
  isPost = false,
  initialPostsToShow = isHomepage ? 10 : 12,
  containerSx = {},
  categoryId,
  title,
  subtitle,
  postId,

  data: {
    allWordpressPost: { nodes: allPosts },
    placeholder,
  },
}) => {
  const location = useLocation()

  const uid = location.pathname

  const { setPostsToShow, getPostsToShow } = useContext(PostsToShowContext)

  const postsToShow = getPostsToShow(uid) ?? initialPostsToShow

  const { theme } = useThemeUI()

  const { getPostViews } = useContext(PostsViewsContext)

  const allFilteredPosts = allPosts
    .filter((post) => {
      // we should exclude top posts on homepage
      if (isHomepage)
        return !post.categories.find((category) => category.name === 'ТОП!')

      return post.categories.find(
        (category) => category.wordpress_id === categoryId
      )
    })
    .filter((post) => post.wordpress_id !== postId)

  const posts = allFilteredPosts.slice(0, postsToShow)

  const topPosts = allFilteredPosts
    .sort((post1, post2) => post2.fields.viewCount - post1.fields.viewCount)
    .slice(0, 5)

  return (
    <div
      sx={{
        variant: 'boxes.container',
        mt: 7,
        mb: 9,
        bg: 'background',
        ...containerSx,
      }}
    >
      {subtitle && (
        <p
          sx={{
            mb: 2,
            color: 'tertiary',
            fontWeight: 'bold',
            fontSize: 1,
            textTransform: 'uppercase',
          }}
        >
          {subtitle}
        </p>
      )}
      {title && <h3 sx={{ fontSize: [3, 3, 4] }}>{title}</h3>}
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: [
            '1fr',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
          ],
          gridTemplateAreas: isHomepage
            ? [
                '"." "." "." "." "top-posts" "top-posts"',
                '". ." ". top-posts" ". top-posts"',
                '". ." ". top-posts" ". top-posts"',
                '". . ." ". . top-posts" ". . top-posts"',
              ]
            : 'none',
          gridAutoRows: '460px',
          gap: 5,
          mb: 9,
        }}
      >
        {posts.slice(0, isHomepage ? 5 : 0).map((post, i) => (
          <div key={post.id}>
            <Card
              isBig={i === 0}
              slug={post.slug}
              title={post.title}
              tags={post.categories}
              date={post.date}
              image={post.featured_media?.localFile ?? placeholder}
              timeToRead={post.fields.readingTime.minutes}
              views={getPostViews(post.wordpress_id) ?? post.fields.viewCount}
              excerpt={post.excerpt}
            />
          </div>
        ))}
        {isHomepage && (
          <div
            sx={{
              gridArea: 'top-posts',
              display: 'flex',
              flexDirection: 'column',
              px: [4, 5],
              py: 5,
              border: '2px solid',
              borderColor: 'topPostsBorder',
              borderRadius: 0,
              bg: 'muted',
            }}
          >
            <h3
              sx={{
                fontSize: 3,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              ТОП-5 ЧИТАЕМОГО
            </h3>
            <div
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {topPosts.map((post, i) => {
                return (
                  <div
                    key={post.wordpress_id}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <span
                      sx={{
                        mr: 3,
                        color: 'text',
                        fontFamily: 'heading',
                        fontSize: [5, '60px'],
                        fontWeight: 'bold',
                        lineHeight: 1,
                      }}
                    >
                      {i + 1}/
                    </span>
                    <div sx={{ maxWidth: '430px', width: '100%' }}>
                      <Tag
                        name={post.categories[0].name}
                        slug={post.categories[0].slug}
                        color={post.categories[0].description}
                      />
                      <Link
                        to={`/${post.slug}`}
                        sx={{
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        <h3
                          sx={{
                            my: 2,
                            fontSize: 2,
                            lineHeight: 'body',
                          }}
                        >
                          {post.title}
                        </h3>
                      </Link>
                      <span
                        sx={{
                          display: 'flex',
                          alignIterm: 'center',
                          fontSize: 0,
                        }}
                      >
                        <EyeIcon
                          color={theme.colors.text}
                          sx={{ ml: 2, mr: 1 }}
                        />
                        {getPostViews(post.wordpress_id) ??
                          post.fields.viewCount}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}

        {posts.slice(isHomepage ? 5 : 0).map((post, i) => (
          <div key={post.id}>
            <Card
              isBig={i === 0}
              slug={post.slug}
              title={post.title}
              tags={post.categories}
              date={post.date}
              image={post.featured_media?.localFile ?? placeholder}
              timeToRead={post.fields.readingTime.minutes}
              views={getPostViews(post.wordpress_id) ?? post.fields.viewCount}
              excerpt={post.excerpt}
            />
          </div>
        ))}
      </div>
      {postsToShow < allFilteredPosts.length && !isPost && (
        <Button
          sx={{
            variant: 'buttons.link',
            bg: 'secondary',
            mx: 'auto',
            px: 7,
            py: 3,
            borderRadius: '100px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            letterSpacing: '.05rem',
            fontFamily: 'body',
          }}
          onClick={debounce(() => setPostsToShow(uid, postsToShow + 12), 250)}
        >
          Загрузить больше
        </Button>
      )}
    </div>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost(sort: { fields: date, order: DESC }) {
          nodes {
            id
            wordpress_id
            slug
            title
            excerpt
            date
            categories {
              wordpress_id
              name
              slug
              description
            }
            fields {
              readingTime {
                minutes
              }
              viewCount
            }
            featured_media {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 680, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        placeholder: file(relativePath: { eq: "placeholder.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 680, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => <PostsList data={data} {...props} />}
  />
)
